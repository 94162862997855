var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: { title: "Foto", active: _vm.popupActive, id: "webcamPopup" },
          on: {
            "update:active": function ($event) {
              _vm.popupActive = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "area_webcam vs-con-loading__container",
              attrs: { id: "webcam-loading" },
            },
            [
              _c("video", { attrs: { autoplay: "true", id: "webCamera" } }),
              _c("input", {
                attrs: { type: "hidden", id: "base_img", name: "base_img" },
              }),
              _c(
                "button",
                { attrs: { type: "button" }, on: { click: _vm.takeSnapShot } },
                [_vm._v("Tirar foto e salvar")]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }